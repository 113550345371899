import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Headline.module.scss';

const Headline = ({ priority, size, centered, children }) => {
  const Tag = `h${priority}`;
  return (
    <Tag
      className={classNames(
        styles.headline,
        `${styles[`headline--${size}`]} ${
          centered ? styles[`headline--centered`] : ''
        }`
      )}
    >
      {children}
    </Tag>
  );
};

Headline.propTypes = {
  centered: PropTypes.bool,
  children: PropTypes.node.isRequired,
  priority: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['primary', 'secondary']),
};

Headline.defaultProps = {
  size: 'primary',
  centered: false,
};

export default Headline;
