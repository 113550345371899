/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useCallback } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { errorEvent } from 'src/lib/analytics';

import Headline from '../lander-text/Headline';
import Body from '../lander-text/Body';

import styles from './ErrorMessaging.module.scss';

const ErrorMessaging = ({ error }) => {
  const parsedErrorMessage = useCallback(() => {
    switch (error) {
      case 301:
        return 'Moved Permanently';
      case 400:
        return 'Bad Request';
      case 404:
        return 'Not Found';
      case 500:
        return 'Internal Server Error';
      default:
        return 'Error';
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      const errorMessage = parsedErrorMessage(error);

      // log in GA
      errorEvent(error, errorMessage);
    }
  }, [error, parsedErrorMessage]);

  return (
    <div className={styles.error}>
      <Headline priority={1}>
        Oops! We can't find the page you're looking for.
      </Headline>
      <div className={styles.error__message}>
        <Body>
          UExpress answers a lot of questions. This is one, however, that we
          can't answer. Use the menu or return to
          {' '}
          <Link href="/">
            <a className={styles.error__link}>the homepage</a>
          </Link>
          {' '}
          to continue exploring.
        </Body>
      </div>
      <div className={styles.error__code}>
        <Body centered>{`${error} ${parsedErrorMessage(error)}`}</Body>
      </div>
    </div>
  );
};

ErrorMessaging.propTypes = {
  error: PropTypes.number.isRequired,
};

export default ErrorMessaging;
