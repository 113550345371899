import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './Body.module.scss';

const Body = ({ element, children, centered, padded }) => {
  const Tag = element;
  // set element to 'div' to nest other elements and apply styles to those elements
  // leave element as 'p' to use basic paragraph styles
  // see /styleguide for examples
  return (
    <Tag
      className={classNames(styles.body, {
        [styles.body_centered]: centered,
        [styles.body_padded]: padded,
      })}
    >
      {children}
    </Tag>
  );
};

Body.propTypes = {
  centered: PropTypes.bool,
  children: PropTypes.node.isRequired,
  element: PropTypes.string,
  padded: PropTypes.bool,
};

Body.defaultProps = {
  element: 'p',
  centered: false,
  padded: false,
};

export default Body;
